import React from 'react'
import {toScale} from "./fn";
class CustomComponent extends React.Component{
    static app=null;
    setState(state, callback=state=>state) {
        return new Promise((resolve, reject)=>{
            super.setState(state,(...args)=>{
                callback(...args);
                resolve(state);
            });
        });
    }
    componentDidMount() {
        window.addEventListener('scroll',this.onScroll);
        window.scrollTo(0,0);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll',this.onScroll)
    }
    onScroll=(event)=>{
        let {scrollY}=window;
        this.setState({scrollY});
        this.scroll(scrollY);
    }
    scroll(scrollY){

    }
    getRefRect(ref){
        let {innerWidth:w,innerHeight:h}=window;
        let rect={
            left:0,
            top:0,
            bottom:0,
            right:0,
            width:0,
            height:0
        };
        if(!ref)return rect;
        else {
            let rr=ref.getBoundingClientRect();
            Object.keys(rect).forEach(k=>rect[k]=rr[k]);
        }
        rect.bottom=h-rect.top;
        return rect;
    }
    getProportion(value,max){
        let p=value>max?1:value/max;
        p=p>0?p:0;
        return p;
    }
    toScale(v){
        return toScale(v);
    }

    contentBlocksEffect(ref,trigger=120,distance=120){
        let rect=this.getRefRect(ref);
        trigger=this.toScale(trigger);
        return this.getProportion(rect.bottom-trigger,distance);
    }
}
export default CustomComponent;
