import React, {Component} from 'react';
import CustomComponent
    from "../../public/CustomComponent";
import PageMobileContent from "../../public/mobile/PageMobileContent";
import "../../css/mobile/page-investment-mobile.scss"
import ToggleVisible from "../../public/ToggleVisible";
import {NavLink} from "react-router-dom";
import {PUBLIC_URL} from "../../public/G";
const refs={};
export default class Investment extends CustomComponent {
    state={
        moreEnd:false,
        logoTotal:21
    }
    more(){
        this.setState({logoTotal:30,moreEnd: true})
    }
    render() {
        let {moreEnd,logoTotal}=this.state;
        let {innerHeight:wh}=window;
        return (
            <PageMobileContent className={`page-investment-mobile`} bg={`${PUBLIC_URL}/images/mobile/investment-bg.jpg`} id={`investment`} bottomBtns={false}>
                <div className="content-box" ref={ref=>refs.cb1=ref} >
                    <div style={(e=>{
                        let styles={};
                        let v=this.contentBlocksEffect(refs.cb1);
                        styles.opacity=v;
                        styles.transform=`translateY(${(1-v)*this.toScale(100)}px)`;
                        return styles;
                    })()}>
                        <h1 className="page-title">投资案例</h1>
                        <p className="page-desc">Investment Profile</p>
                    </div>
                    <div ref={ref=>refs.logoList=ref}>
                        <p className="page-subdesc font-size26 margin-top40" style={(e=>{
                            let styles={};
                            let v=this.contentBlocksEffect(refs.logoList);
                            styles.opacity=v;
                            styles.transform=`translateY(${(1-v)*this.toScale(100)}px)`;
                            return styles;
                        })()}>
                            部分被投企业展示
                        </p>
                        <ul className="logo-list flex h space-between">
                            {
                                Array.from({length:logoTotal},(item,index)=>{
                                    return (
                                        <li className="logo"  style={(e=>{
                                            let style={};
                                            let rect=this.getRefRect(refs.logoList);
                                            let trigger=index*this.toScale(12)+this.toScale(100);
                                            let v=this.getProportion(rect.bottom-trigger,120);
                                            style.opacity=v;
                                            style.transform=`rotateY(${(1-v)*45}deg) translateY(${(1-v)*this.toScale(100)}px) scale(${v})`
                                            return style;
                                        })()}><img src={`${PUBLIC_URL}/images/investment-trademark${index+1}.png`}/></li>
                                    )
                                })
                            }
                        </ul>
                        <ToggleVisible visible={!moreEnd}>
                            <div className="look-more" onClick={e=>this.more()}>查看更多</div>
                        </ToggleVisible>
                    </div>
                </div>
            </PageMobileContent>
        )
    }
}