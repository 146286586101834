import axios from 'axios';
import apiConfig from './ApiConfig';
import LocalStorage from './LocalStorage';
let isTest=true;
if('development'===process.env.NODE_ENV){
    isTest=true;
}else if(process.env.NODE_ENV==="production"){
    isTest = false;
}
//console.log(isTest,'isTest')
var ajax = axios.create({
    baseURL: isTest?'http://router.striveing.com:3000/api/api/web/':(window.$BASEURL||'../backstage/api/web/'),
    timeout: 1000*60,
    method:"post",
});
// 添加请求拦截器
ajax.interceptors.request.use( function (config) {
    // console.log(config,'token');
	config.headers.openid=LocalStorage.token||"null";
    //config.header.token =
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
ajax.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    let result=response.data;
    if(result.status==401){
        //browserHistory.replace('/');
        return  Promise.reject(result);
    }
    if(result.status!=1)return Promise.reject(result);
    //console.log(result,'result')
    return result;
}, function (error) {
    //console.log(error,'error')
    // 对响应错误做点什么
    return Promise.reject({status:-1,data:error});
});
export default {
    async post(api,data={},config){
        let _apiconfig=apiConfig[api];
        if(!_apiconfig)return  Promise.reject(`${api} 不存在`);
        return  await ajax.post(_apiconfig.api,data,config);
    }
};
