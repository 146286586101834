import React, {Component} from 'react';
import CustomComponent
    from "../../public/CustomComponent";
import Index from "./Index";
import MobileMain from "../../public/mobile/MobileMain";
import Header from "../../public/mobile/Header";
import About from "./About";
import Investment from "./Investment";
import {scrollAnimation, toScale} from "../../public/fn";
import Contact from "./Contact";
import News from "./News";
let refs={};
export default class MobilePage extends CustomComponent {
    state={
        id:null
    };
    pause=false;
    getScrollTop(element){
        return element.offsetTop-toScale(136)/2;
    }
    getElement(router){
        let id=router.pathname.replace('/','')||"index";
        let element=document.getElementById(id);
        return element;
    }
    toScroll(router){
        let element=this.getElement(router);
        if(element){
            this.pause=true;
            this.setState({id:element.id});
            let top=this.getScrollTop(element);
            scrollAnimation(top).then(e=>this.pause=false);
        }
    }
    componentDidMount() {
        super.componentDidMount();
        this.unlisten = this.props.history.listen((location, action) => {
            this.toScroll(location);
        });
        this.toScroll(this.props.location);
    }
    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        let {id}=this.state;
        return (
            <div className={`mobile-page`}>
                <Header pageId={id}/>
                <MobileMain>
                    <Index/>
                    <About/>
                    <Investment/>
                    <News/>
                    <Contact/>
                </MobileMain>
            </div>
        );
    }
}