import React
    from 'react';
import CustomComponent
    from "./CustomComponent";
import Main from "../public/Main";
import {PUBLIC_URL} from "./G"
const refs={};
export default class Footer extends CustomComponent {
    get footerRef() {
        return refs.footer;
    }
   render() {
       let {innerHeight:wh}=window;
       let {white=false}=this.props;
       return (
           [
               <Main className={`footer-block slide-block position-sticky  ${white?'white':''}`} setRef={ref=>refs.footer=ref} style={(e=>{
                   let style={};
                   let rect=this.getRefRect(refs.footer);
                   let trigger=0;
                   let v=this.getProportion(rect.bottom-trigger,wh);
                   style.display="flex"//rect.bottom>0?'flex':'none';
                   return style;
               })()}>
                   <div className="layer-bg" style={(e=>{
                       let style={};
                       let rect=this.getRefRect(refs.footer);
                       let trigger=0;
                       let v=this.getProportion(rect.bottom-trigger,100);
                       style.opacity=v;
                       return style;
                   })()}/>
                   <div className={`footer flex center `}>
                       <div className="main-content-box footer-item-box">
                           <div className="footer-item flex center" style={(e=>{
                               let style={};
                               let rect=this.getRefRect(refs.footer);
                               let trigger=0;
                               let v=this.getProportion(rect.bottom-trigger,wh);
                               style.opacity=v;
                               style.transform=`translateX(${(1-v)*300}px)`;
                               return style;
                           })()}>
                               <div className={`item-box flex h space-between`}>
                                   <div className="info1 flex  by-center style-1" >
                                       <div>
                                           <p className="btn">查看被投资企业</p>
                                           <p className="text">提交商业计划书 <a href="mailto:BP@guangzhicapital.com">BP@guangzhicapital.com</a></p>
                                           <p className="text">工作机会请投递  <a href="mailto:BP@guangzhicapital.com">HR@guangzhicapital.com</a></p>
                                       </div>
                                   </div>
                                   <div className="info1 flex  by-center style-2">
                                       <div>
                                           <br/>
                                           <br/>
                                           <p className="text">提交商业计划书 <a href="mailto:BP@guangzhicapital.com">BP@guangzhicapital.com</a></p>
                                           <p className="text">工作机会请投递  <a href="mailto:BP@guangzhicapital.com">HR@guangzhicapital.com</a></p>
                                       </div>
                                   </div>
                                   <div className="info2 style-1">
                                       <p className="site" style={{backgroundImage: `url(${PUBLIC_URL}/images/logo-1.svg)`}}>
                                           <a href="https://www.guangzhicapital.com">www.guangzhicapital.com</a><br/>

                                       </p>
                                       <p className="area">北京市朝阳区新源南路8号启皓北京西塔9层  100027<br/></p>
                                   </div>
                                   <div className="info2 style-2">
                                       <p className="name">光智资本北京办公室</p>
                                       <p className="area">北京市朝阳区新源南路8号启皓北京西塔9层 100027<br/><a href="https://www.guangzhicapital.com">www.guangzhicapital.com</a><br/></p>
                                   </div>
                               </div>
                           </div>
                           <div className="footer-item flex center" style={(e=>{
                               let style={};
                               let rect=this.getRefRect(refs.footer);
                               let trigger=0;
                               let v=this.getProportion(rect.bottom-trigger,wh);
                               style.opacity=v;
                               style.transform=`translateX(${-((1-v)*300)}px)`;
                               return style;
                           })()}>
                               <div className={`item-box flex h space-between`}>
                                   <div className="info3 flex h">
                                       <img src={`${PUBLIC_URL}/images/qr.jpg`} className={`img`}/>
                                       <p className="info-text">
                                           扫码关注<br/>
                                           企业微信公众号
                                       </p>
                                   </div>
                                   {/*<div className="info4">
                                       <p className="site" style={{backgroundImage:`url(${PUBLIC_URL}/images/logo-2${white?'-black':''}.svg)`}}><a href="www.everbright.com">www.everbright.com</a></p>
                                       <p className="site" style={{backgroundImage:`url(${PUBLIC_URL}/images/logo-3${white?'-black':''}.svg)`}}><a href="www.tslsmart.com">www.tslsmart.com</a></p>
                                   </div>*/}
                               </div>
                           </div>
                       </div>
                       <div className={`infos-box`}>
                           <div className="main-content-box ">
                               <div className="infos flex space-between by-center" style={(e=>{
                                   let style={};
                                   let rect=this.getRefRect(refs.footer);
                                   let trigger=0;
                                   let v=this.getProportion(rect.bottom-trigger,wh);
                                   style.opacity=v;
                                   style.transform=`translateY(${100-100*v}px)`;
                                   return style;
                               })()}>
                                   <p className="info-text left">
                                       ©2021 Capital Co., Ltd. All Rights Reserve<br/>
                                       京ICP备2021040149号-1 | <img src={`${PUBLIC_URL}/images/ga.png`} width='36' height='40' style={{width:'20px',height:'20px',padding:'0 4px 0 0'}}/><a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010502055185" rel="noreferrer"target="blank">京公网安备 11010502055185号</a> | Designed by QIANMA
                                   </p>
                                   <p className="info-text ">
                                       |&nbsp;&nbsp;&nbsp;&nbsp;法律信息&nbsp;&nbsp;&nbsp;&nbsp;|
                                   </p>
                               </div>
                           </div>
                       </div>
                   </div>
               </Main>
           ]
       );
   }
}
