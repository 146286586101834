import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
export const onResizeEventName="resize";//'onorientationchange' in window ? 'orientationchange' : 'resize';
export function isLandscape(){
    return window.innerWidth/window.innerHeight>1;
    //return Math.abs(window.orientation) === 90|| window.orientation===undefined;
}

export function isPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone",/* "iPad", */"iPod");
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
}
export function toScale(v){
    return v*(window.$appScale||1);
}
export function scrollAnimation(top){
    window.scrollTo({
        top,
        behavior:'smooth'
    });
    return new Promise((resolve, reject)=>{
        setTimeout(resolve,2000);
    })
}