import React, {Component} from 'react';
import CustomComponent
    from "../../public/CustomComponent";
import moment from 'moment'
import Header from "../../public/mobile/Header";
import MobileMain from "../../public/mobile/MobileMain";
import "../../css/mobile/page-index-mobile.scss"
import PageMobileContent from "../../public/mobile/PageMobileContent";
import {NavLink} from "react-router-dom";
import {PUBLIC_URL} from "../../public/G";
import FrameVideos from "../../public/mobile/FrameVideos";
const refs={

};
export default class Index extends CustomComponent {
    state={
        playType:1
    }
    componentDidMount() {
        super.componentDidMount();
        /*let autoplay=function() {
            document.querySelectorAll('video').forEach(video=>{
                video.load();
            })
        };
        document.addEventListener(
            'WeixinJSBridgeReady',
            autoplay,
            false
        );*/
        //refs.video1.play();
    }
    onVideoEnded(type){
        if(type===1){
            refs.video1.pause();
            refs.video2.play();
            this.setState({playType:2})
        }else if(type===2){
            refs.video2.pause();
            refs.video1.play()
            this.setState({playType:1})
        }
    }
    render() {
        let {nextRef}=this.props;
        let {playType}=this.state;
        return (
            <PageMobileContent className={`page-index-mobile`} id={`index`} bottomBtns={false} /*bg={`${PUBLIC_URL}/images/mobile/index-bg.jpg`}*/ layer={false} nextRef={nextRef}>
                <FrameVideos cover={`${PUBLIC_URL}/images/mobile/mobile-index-banner-cover.jpg`}/>
                {/*<div  className={`video-ele-box ${playType==2?'on':''}`}>
                    <video muted="true" webkit-playsinline="true" playsInline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true"
                        x-webkit-airplay="deny" autoPlay={true}
                           x-webkit-wirelessvideoplaybackdisabled="true"  className={`video-ele`}
                           src={`${PUBLIC_URL}/videos/mobile-index-banner2.mp4`} ref={ref => refs.video2 = ref}
                           onEnded={e => this.onVideoEnded(2)}></video>
                </div>
                <div className={`video-ele-box ${playType==1?'on':''}`}>
                    <video muted="true" webkit-playsinline="true" playsInline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true"
                        x-webkit-airplay="deny" autoPlay={true}
                           x-webkit-wirelessvideoplaybackdisabled="true" className={`video-ele`}
                           src={`${PUBLIC_URL}/videos/mobile-index-banner1.mp4`} ref={ref => refs.video1 = ref}
                           onEnded={e => this.onVideoEnded(1)}></video>
                </div>*/}


                <div className="btns flex h space-between">
                    <NavLink to={`/about`}><p className="mobile-btn">关于光智</p></NavLink>
                    <NavLink to={`/investment`}><p className="mobile-btn">投资案例</p></NavLink>
                    <NavLink to={`/news`}><p className="mobile-btn">新闻动态</p></NavLink>
                    <NavLink to={`/contact`}><p className="mobile-btn">联系我们</p></NavLink>
                </div>
            </PageMobileContent>
        );
    }
}