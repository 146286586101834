import React, {Component} from 'react';
import CustomComponent
    from "../../public/CustomComponent";
import PageMobileContent from "../../public/mobile/PageMobileContent";
import "../../css/mobile/page-news-mobile.scss"
import ToggleVisible from "../../public/ToggleVisible";
import {NavLink} from "react-router-dom";
import {news, PUBLIC_URL} from "../../public/G";
import ajax from "../../public/Ajax";
const refs={};
export default class News extends CustomComponent {
    componentDidMount() {
        super.componentDidMount();
        this.forceUpdate()
        this.getList();

    }

    state={
        moreEnd:false,
        newsList:[],
        pageInfo:{total:0}
    };
    async getList(pageIndex=1){
        let newList=this.state.newsList;
        let result=await ajax.post('news-list',{pageIndex:pageIndex,pageSize:5});
        this.setState({newsList:newList.concat(result.data.list||[]),pageInfo:result.data.pageInfo,pageIndex});
    }
    async loadMore(){
        let pageIndex=this.state.pageIndex;
        await this.getList(++pageIndex)
        setTimeout(e=>{
            this.forceUpdate()
        })
    }
    render() {
        let {moreEnd,newsList,pageInfo}=this.state;
        let {innerHeight:wh}=window;
        let effect=((ref,trigger=0)=>{
            let styles={};
            let v=this.contentBlocksEffect(ref,trigger);
            styles.opacity=v;
            styles.transform=`translateY(${(1-v)*this.toScale(100)}px)`;
            return styles;
        });
        return (
            <PageMobileContent className={`page-news-mobile`} id={`news`} bg={`${PUBLIC_URL}/images/mobile/news-bg.jpg`} bottomBtns={false}>
                <div className="content-box" ref={ref=>refs.cb1=ref} >
                    <div style={effect(refs.cb1)}>
                        <h1 className="page-title">新闻动态</h1>
                        <p className="page-desc">News</p>
                    </div>
                    <div className={`content-blocks-box`}>
                        {
                            newsList.map((item,index)=>(
                                <div ref={ref=>refs[`news-block-${index}`]=ref} key={index}>
                                    <NavLink to={`/content/news/${item.id}`}>
                                        <div className="content-block"  style={effect(refs[`news-block-${index}`])}>
                                            <h1 className="title">{item.title.replace("<br/>",'')}</h1>
                                            <div className="info flex h space-between">
                                                <p className="date">{item.date}</p>
                                                <p className="btn">查看详情</p>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            ))
                        }
                    </div>
                    <ToggleVisible visible={pageInfo.total>newsList.length}>
                        <div className="look-more" onClick={e=>this.loadMore()}>查看更多</div>
                    </ToggleVisible>
                </div>
            </PageMobileContent>
        )
    }
}