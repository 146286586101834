import React
    from 'react';
import CustomComponent from "../CustomComponent";
import ToggleVisible from "../ToggleVisible";
import {NavLink} from "react-router-dom";
import FixedBackground from "./FixedBackground";
import {PUBLIC_URL} from "../G";
export default class PageMobileContent extends CustomComponent{
    componentDidMount() {
        super.componentDidMount();
        this.forceUpdate();
        let pageMobiles=[...document.querySelectorAll('.page-mobile')];
        let index=pageMobiles.findIndex(ref=>this.ref===ref);
        this.nextRef=pageMobiles[index+1];
    }
    render() {
        let {className,children,layer=true,getV,bottomBtns=true,id='',bg=null,onClick,cover,moreBtns=[],bottomBtnsVisible=true}=this.props;
        let nextRef=this.nextRef;
        let {innerHeight:wh}=window;
        let nextV=0;
        let currentV=0;
        if(nextRef){
            let rect=this.getRefRect(nextRef);
            let trigger=0;
            nextV=this.getProportion(rect.bottom-trigger,wh/2);
        }
        if(this.ref){
            let rect=this.getRefRect(this.ref);
            let trigger=0;
            currentV=this.getProportion(rect.bottom-trigger,wh/2);
        }
        if(getV)getV({currentV,nextV});

        let {scrollY}=this.state||{};
        return (
            <div className={`page-mobile ${className}`} onClick={onClick} ref={ref=>this.ref=ref} id={id} style={(e=>{
                let style={};
                //style.backgroundPosition=`center ${-nextV*this.toScale(400)}px`;
                return style;
            })()}>

                <ToggleVisible visible={!!bg}>
                    <FixedBackground bg={bg} cover={cover}/>
                </ToggleVisible>
                <ToggleVisible visible={layer}>
                    <div className="layer-bg" style={(()=>{
                        let style={};
                        style.opacity=currentV;
                        return style;
                    })()}/>
                </ToggleVisible>
                <div className="page-mobile-content">
                    {children}
                    <ToggleVisible visible={bottomBtns}>
                        <div className="bottom-btns flex h">
                            <ToggleVisible visible={!!bg}>
                                <FixedBackground bg={bg} cover={cover} setTranslateY={(rect)=>{
                                    let newrect=this.getRefRect(this.ref);
                                    let y=-newrect.bottom+rect.height;
                                    let sh=(newrect.bottom-newrect.height);
                                    y=y<-(wh-rect.height)?-(wh-rect.height):y;
                                    if(sh>0){
                                        y+=sh;
                                    }
                                    return y;
                                }}/>
                                <div className="layer-bg"  style={(()=>{
                                    let style={};
                                    style.opacity=currentV;
                                    return style;
                                })()}/>
                            </ToggleVisible>
                            <ToggleVisible visible={bottomBtnsVisible}>
                                <div className="btns-box">
                                    {moreBtns}
                                    <NavLink to={`/team`}>
                                        <div className="mobile-btn">
                                            <img src={`${PUBLIC_URL}/images/mobile/team-btn.svg`}/>
                                        </div>
                                    </NavLink>
                                    <NavLink to={`/investment`}>
                                        <div className="mobile-btn">
                                            <img src={`${PUBLIC_URL}/images/mobile/case-btn.svg`}/>
                                        </div>
                                    </NavLink>
                                </div>
                            </ToggleVisible>
                        </div>
                    </ToggleVisible>
                </div>
            </div>
        );
    }
}