import React, {Component} from 'react';
import CustomComponent
    from "../../public/CustomComponent";
import "../../css/mobile/page-about-mobile.scss"
import PageMobileContent from "../../public/mobile/PageMobileContent";
import ToggleVisible from "../../public/ToggleVisible";
import {PUBLIC_URL} from "../../public/G";
import {NavLink} from "react-router-dom";
let v={
    currentV:0,
    nextV:0
};
let refs={};
const aboutDaraList = [
    {
        num:400,
        unit:"亿",
        desc:"管理资产规模突破400亿人民币"
    },
    {
        num:100,
        unit:"家",
        desc:"团队累计投资企业超过100家"
    },
    {
        num:20,
        unit:"家",
        desc:"通过近20家企业IPO或 并购实现退出"
    }
];
const aboutHonorList=[
    {desc:`2021<br/>年度中国最佳创投人<br/>TOP 100`, icon:"logo-forbes"},
    {desc:`2021<br/>年中国最受创业者欢迎投资人<br/>TOP 100`, icon:"logo-36k"},
    {desc:`2021<br/>中国前沿科技领域投资人<br/>TOP 20`, icon:"logo-36k"},
    {desc:`2021<br/>年最受中国创业者欢迎私募<br/>股权投资机构TOP 50`, icon:"logo-36k"},
    {desc:`2021<br/>年度中国最佳创投人<br/>TOP 100`, icon:"logo-forbes"},
    {desc:`2021<br/>年度智慧城市影响力人物`, icon:"logo-jiemianxinwen"},
    {desc:`2020<br/>年度中国最佳创投人<br/>TOP 100`, icon:"logo-forbes"},
    {desc:`2020<br/>年度中国最佳投资人奖项`, icon:"logo-aimeizixun"},
    {desc:`2020<br/>Edge Awards 全球创新评选<br/>TOP 50 `, icon:"logo-taimeiti"},
    {desc:`2020<br/>Edge 50 私募股权榜单`, icon:"logo-taimeiti"},
    {desc:`2020<br/>金牛大数据和人工智能领域<br/>投资机构`, icon:"logo-zhongguozhengjuanbao"},
    {desc:`2020<br/>私募股权投资机构<br/>TOP 20`, icon:"logo-21shijiwang"},
    {desc:`2020<br/>年度中国最受创业者欢迎私募<br/>股权投资机构TOP 50`, icon:"logo-36k"},
    {desc:`2020<br/>年度中国最受LP认可私募投资<br/>机构TOP 30`, icon:"logo-36k"},
];
export default class About extends CustomComponent {
    state={
        aboutHonorList:aboutHonorList.slice(0,4),
        moreHonorEnd:false,
        introduceShow:false
    };
    componentDidMount() {
        super.componentDidMount();
        this.forceUpdate();
    }
    moreHonor() {
        this.setState({aboutHonorList, moreHonorEnd: true});
        setTimeout(e=>{
            this.forceUpdate();
        });
    }
    moreDetails(){
        this.setState({introduceShow: true});
        setTimeout(e=>{
            this.forceUpdate();
        });
    }
    render() {
        let {aboutHonorList,moreHonorEnd,introduceShow}=this.state;
        let {innerHeight:wh}=window;
        const contentBlocksEffect=ref=>{
            let styles={};
            let v=this.contentBlocksEffect(ref,100);
            styles.opacity=v;
            styles.transform=`scale(${(1.5-v*0.5)})`;
            return styles;
        };
        let introduceRect=this.getRefRect(refs.introduce);
        let moreBtns=[
            <ToggleVisible visible={introduceRect.bottom<=0}>
                <NavLink to={`/about`}>
                    <div className="mobile-btn">
                        <img src={`${PUBLIC_URL}/images/mobile/details-btn.svg`} onClick={e=>this.moreDetails()}/>
                    </div>
                </NavLink>
            </ToggleVisible>
        ];
        let bottomBtnsVisible=refs.introduce?!(introduceRect.bottom<introduceRect.height+this.toScale(136)):true;
        return (
            <PageMobileContent className={`page-about-mobile padding-top`}  bg={`${PUBLIC_URL}/images/mobile/about-bg.jpg`} id={`about`} getV={_v=>v=_v} moreBtns={moreBtns} bottomBtnsVisible={bottomBtnsVisible}>
                <div className="content-box" ref={ref=>refs.cb1=ref} >
                    <div style={(e=>{
                        let styles={};
                        let v=this.contentBlocksEffect(refs.cb1);
                        styles.opacity=v;
                        styles.transform=`translateY(${(1-v)*this.toScale(100)}px)`;
                        return styles;
                    })()}>
                        <h1 className="page-title">光智资本</h1>
                        <p className="page-desc">ET Capital</p>
                        <br/>
                    </div>
                    <ToggleVisible visible={!introduceShow}>
                        <div className={`content-blocks-box`}>
                            {
                                aboutDaraList.map((item,index)=>(
                                    <div  ref={ref=>refs[`cbb${index}`]=ref}  >
                                        <div className="content-blocks" style={contentBlocksEffect(refs[`cbb${index}`])}>
                                            <p className="count">
                                                <span className="num">{item.num}</span>
                                                <span className="unit">{item.unit}</span>
                                            </p>
                                            <p className="desc">{item.desc}</p>
                                            {/*<ToggleVisible visible={index===aboutDaraList.length-1}>
                                                <NavLink to={`/about`}>
                                                    <div className="more-details" onClick={e=>this.moreDetails()}>
                                                        查看详情
                                                    </div>
                                                </NavLink>
                                            </ToggleVisible>*/}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </ToggleVisible>
                    <ToggleVisible visible={introduceShow}>
                        <p className="introduce" ref={ref=>refs.introduce=ref}>
                            光智资本由光大控股联合特斯联共同发起设立，由光大控股新经济迭代而成。依托光大控股千亿金融央企资源，特斯联全球领先的人工智能物联网技术，及二者庞大的产业生态圈层。光智资本围绕“科技+”整体投资策略，覆盖高端制造、绿色科技、企业服务、新消费、集成电路等领域发掘优质项目创造持久价值。<br/>
                            <br/>
                            团队过往资产管理规模已突破400亿元，累计投资超过100家企业，同时在项目的获取和投资上，先后通过近20家企业完成IPO或并购实现退出。<br/>
                            <br/>
                            过往的4年中，团队凭借严谨的投研体系及专业的资产管理能力，先后投出了诸多头部项目：商汤科技、特斯联、寒武纪、第四范式、奇安信、京东物流、蔚来汽车、小鹏汽车、美团点评、爱奇艺、网易云音乐、马上金融、银联商务、Nreal、Wish、三人行传媒、元气森林、每日优鲜、T11、云迹科技、编程猫等。
                        </p>
                    </ToggleVisible>
                </div>
                <div className="content-box" ref={ref=>refs.cb2=ref}>
                    <div style={(e=>{
                        let styles={};
                        let v=this.contentBlocksEffect(refs.cb2);
                        styles.opacity=v;
                        styles.transform=`translateY(${(1-v)*this.toScale(100)}px)`;
                        return styles;
                    })()}>
                        <h1 className="page-title">投资领域</h1>
                        <p className="page-desc">Sector Focus</p>
                        <br/><br/>
                        <p className="page-desc">围绕“科技+”整体投资策略</p>
                        <br/>
                        <br/>
                    </div>
                    <div className="content-blocks-box">
                        <div  ref={ref=>refs[`cbb${3}`]=ref}>
                            <div className="realm-img"   style={contentBlocksEffect(refs[`cbb3`])}>
                                <img src={`${PUBLIC_URL}/images/mobile/realm.svg`}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-box" ref={ref=>refs.cb3=ref}>
                    <div style={(e=>{
                        let styles={};
                        let v=this.contentBlocksEffect(refs.cb3);
                        styles.opacity=v;
                        styles.transform=`translateY(${(1-v)*this.toScale(100)}px)`;
                        return styles;
                    })()}>
                        <h1 className="page-title">获得荣誉</h1>
                        <p className="page-desc">Awards</p>
                    </div>
                    <div className="content-blocks-box">
                        <div  ref={ref=>refs[`cbb${5}`]=ref}>
                            <div className="content-blocks">
                                <ul className="honor-list">
                                    {
                                        aboutHonorList.map((item,index)=>(
                                            <li className="honor-item flex h space-between by-center" ref={ref=>refs[`honor-item-${index}`]=ref} style={(e=>{
                                                let styles={};
                                                let ref=refs[`honor-item-${index}`];
                                                let rect=this.getRefRect(ref);
                                                let trigger=this.toScale(120);
                                                let v=this.getProportion(rect.bottom-trigger,200);
                                                styles.opacity=v;
                                                styles.transform=`translateX(${(1-v)*this.toScale(200)*(index%2===0?1:-1)}px)`;
                                                return styles;
                                            })()}>
                                                <p className="desc" dangerouslySetInnerHTML={{__html:item.desc}}></p>
                                                <p  className={`honor-icon`}>
                                                    <img src={`${PUBLIC_URL}/images/mobile/${item.icon}.png`}/>
                                                </p>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <ToggleVisible visible={!moreHonorEnd}>
                                    <div className="look-more" onClick={e=>this.moreHonor()}>查看更多</div>
                                </ToggleVisible>
                            </div>
                        </div>
                    </div>
                </div>
            </PageMobileContent>
        );
    }
}