import React, {Component} from 'react';
import CustomComponent
    from "../public/CustomComponent";
import '../css/index.scss';
import Header from "../public/Header";
import Swiper from "../public/Swiper";
import Main from "../public/Main";
import {NavLink} from "react-router-dom";
import  "../css/page-contact.scss"
import Footer from "../public/Footer";
import MouseAnimation from "../public/MouseAnimation";
const refs={};
export default class Contact extends CustomComponent {
    render() {
        let {innerHeight:wh}=window;
        let bannerEff=(e=>{
            let style={};
            let rect=this.getRefRect(refs.slide2);
            let trigger=0;
            let twh=wh;
            let v=this.getProportion(rect.top-trigger,twh);
            // style.backgroundPosition=`center ${((v*this.toScale(300)))-this.toScale(300)}px`;
            // style.opacity=v;
            return style;
        })();
        return (
            <div className={`page-contact`}>
                <Header status={0} index={5}/>
                <div className="main-content body-main position-sticky slide-block">
                    <div className="banner">
                        <div className="image-item"  style={{backgroundPosition:bannerEff.backgroundPosition}}>
                            <div className="layer-bg-black "/>
                            <div className="main-content-box">
                                <div className="banner-title animate__animated animate__backInDown">
                                    <p className="big" style={{transform:`translateY(${(1-bannerEff.opacity)*this.toScale(-400)}px)`,opacity:bannerEff.opacity}}>联系我们</p>
                                    <p className="sub" style={{transform:`translateY(${(1-bannerEff.opacity)*this.toScale(-300)}px)`,opacity:bannerEff.opacity}}>Contact Us</p>
                                </div>
                            </div>
                        </div>
                        <MouseAnimation/>
                    </div>
                </div>
                <Footer white={true}/>
            </div>
        );
    }
}