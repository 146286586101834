export let UserKey={
    token:"token",
    no:'no',
    router:'router',
    stepName:"stepName"
};
export default  class LocalStorage {
    static set token(value){
        if(!value)return;
        this.set(UserKey.token,value);
    }
    static get token(){
        return this.get(UserKey.token);
    }
    static get no(){
        return this.get(UserKey.no);
    }
    static set no(value){
        if(!value)return;
        this.set(UserKey.no,value);
    }
    static get router(){
        return this.get(UserKey.router);
    }
    static set router(value){
        if(!value)return;
        this.set(UserKey.router,value);
    }
    static get stepName(){
        return this.get(UserKey.stepName);

    }
    static set stepName(value){
        this.set(UserKey.stepName,value);
    }
    static set(key,value){
        localStorage.setItem(key,value);
    }
    static get(key){
        let value=localStorage.getItem(key);
        let json;
        try {
            json=JSON.parse(value);
        }catch (e) {
            json=value;
        }
        return json;
    }
}
