import React
    from 'react';
import CustomComponent
    from "../CustomComponent";
import ToggleVisible from "../ToggleVisible";
import {PUBLIC_URL} from "../G";
import jszip from "jszip";
import axios from 'axios'
import ImageFramePlayer from 'image-frame-player';
export default class FrameVideos extends CustomComponent {
    state={
        loading:true
    }
    componentDidMount() {
        super.componentDidMount();
        this.load(this.videoRef);
    }
    async load(videoRef){
        let url=`${PUBLIC_URL}/videos/mobile-index-banner.zip`;
        let response=await axios.get(url,{
            responseType: 'blob'
        });
        let zip=await jszip.loadAsync(response.data);
        let files=Object.values(zip.files);
        let images=[];
        for (var i=0;i<files.length;i++){
            let file=files[i];
            let base64=await zip.file(file.name).async('base64');
            images.push(`data:image/jpg;base64,${base64}`);
        }
        var imageFramePlayer = new ImageFramePlayer({
            dom : this.videoRef,
            imgArr : images,
            fps : 12,
            useCanvas : true,
            loop : -1,
            yoyo : false
        });
        this.imageFramePlayer=imageFramePlayer;
        imageFramePlayer.play();
        this.setState({loading: false})
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        if(this.imageFramePlayer)this.imageFramePlayer.destroy();
    }

    render() {
        let {cover}=this.props;
        let {loading}=this.state;
        return (
            <div className="frame-videos-content">
                <div className={`frame-videos`} style={{backgroundImage:`url(${cover})`}} ref={ref=>this.videoRef=ref}/>
                <ToggleVisible visible={loading}>
                    <img src={`${PUBLIC_URL}/images/loading.svg`} className={`loading`}/>
                </ToggleVisible>
            </div>
        );
    }
}