import React, {Component} from 'react';
import CustomComponent
    from "../public/CustomComponent";
import '../css/index.scss';
import Header from "../public/Header";
import Swiper from "../public/Swiper";
import Main from "../public/Main";
import {NavLink} from "react-router-dom";
import Footer from "../public/Footer";
import ImagesLoaded from 'react-images-loaded';
import {DisplacementSlider} from "../public/displacementSlider"
import MouseAnimation from "../public/MouseAnimation";
import {news,PUBLIC_URL} from "../public/G";
import moment from 'moment'
import ajax from "../public/Ajax"
const refs={};
let  timer=null;
export default class Index extends CustomComponent{
    swiperComp=null;
    banners=[
        {
            url:`${PUBLIC_URL}/images/banner1.jpg`,
            status:0
        },
        {
            url:`${PUBLIC_URL}/images/banner2.jpg`,
            status:0
        }
    ];
    state={
        swiperIndex:0,
        options:{},
        videoEnded:false,
        newsList:[],
    };

    sliderChange(){
        let {swiper}=this.swiperComp;
        console.log(swiper.activeIndex,'activeIndex')
        this.setState({swiperIndex: swiper.activeIndex});
        //console.log(swiper,'swiper')
    }
    componentDidMount() {
        super.componentDidMount();
        let baseOptions={
            spaceBetween: 30,
            effect: "fade",
            autoplay : {
                delay:3000
            },
        };
        let options={
            ...baseOptions,
            on:{
                slideChangeTransitionEnd:this.sliderChange.bind(this)
            }
        };
        this.onVideoPlay();
        this.setState({options});
        window.addEventListener('resize',this.onVideoReSize);
        ajax.post('news-list',{recommended:1}).then((result)=>{
            this.setState({newsList:result.data.list});
        })
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        clearTimeout(timer)
        window.removeEventListener('resize',this.onVideoReSize)
    }

    onVideoPlay(){
        this.setState({videoEnded:false});
        refs.video.play();
    }
    onVideoEnded(){
        //this.setState({videoEnded:true});
        //10s后再次播放
        timer=setTimeout(e=>this.onVideoPlay(),2000);
    }
    /*handleDone(event){
        let images=event.images.map(i=>i.img);
        let {clientHeight,clientWidth}=refs.banner;
        let [firstImage]=images;
        let imageWidth=clientHeight/firstImage.height*firstImage.width;
        let imageHeight=clientHeight;
        let ctr=new DisplacementSlider({
            width:imageWidth,
            height:imageHeight,
            parent:refs.banner,
            images:images
        });
        setTimeout(e=>{
            ctr.goToSlide(1)
        },2000)

    }*/
    onVideoReSize(){
        let video=refs.video;
        let b=video.clientWidth/video.clientHeight;
        // video.style.height=`${window.innerHeight}px`;
        // video.style.width=`${window.innerHeight*b}px`;
    }
    render() {
        let {swiperIndex,scrollY=0,options,videoEnded,newsList}=this.state;
        let {innerHeight:wh}=window;
        let banners=this.banners;

        let scale_300=this.toScale(300);
        let scale_100=this.toScale(100);
        let scale_200=this.toScale(200);
        let totalCount=((count,addDis)=>{
            let rect=this.getRefRect(refs.slide2);
            let trigger=wh/4+this.toScale(addDis);
            let v=this.getProportion(rect.bottom-trigger,this.toScale(150));

            return (count*v).toFixed(0);
        })
        let bannerEff=(e=>{
            let style={};
            let rect=this.getRefRect(refs.slide2);
            let trigger=0;
            let twh=wh;
            let v=this.getProportion(rect.top-trigger,twh);
            //style.backgroundPosition=`center ${((v*this.toScale(200)))-this.toScale(200)-this.toScale(200)}px`;
            //style.opacity=v;
            return style;
        })();
        //let newsList=newsList;//news.filter(item=>item.indexrecommended===1);
        return (
            <div className={`page-index`}>
                {/*<ImagesLoaded
                    elementType={'ul'} // defaults to 'div'
                    className={'banner-images'} // defaults to 'images-loaded-container'
                    done={this.handleDone.bind(this)}
                    background=".image" // true or child selector
                >
                    {
                        banners.map(item=>{
                            return (<img src={item.url}/>)
                        })
                    }
                </ImagesLoaded>*/}
                <Header status={banners[swiperIndex].status}/>
                <div className="main-content body-main position-sticky">
                    <div className="banner">
                        <div className={`image-item`}>
                            <div className="image-item  image-item-content">
                                <div className={`layer-bg`}/>
                                <video muted="true" webkit-playsinline="true" playsInline="true"
                                       x-webkit-airplay="deny"
                                       x-webkit-wirelessvideoplaybackdisabled="true" onCanPlay={e=>this.onVideoReSize()} className="video-ele"
                                       src={`${PUBLIC_URL}/videos/index-banner.mp4`} ref={ref => refs.video = ref}
                                       onEnded={e => this.onVideoEnded()}></video>
                            </div>
                            <div className="image-item image-item-content transition" style={videoEnded?{opacity:1}:{opacity:0}}>
                                <div className="image-item-bg layer-bg" style={{...bannerEff}}/>
                                <div className={`layer-bg`}/>
                                <h1 className={`title`}>
                                    <span className={`arrow left`} onClick={e=>{this.swiperComp.swiper.slidePrev(500)}} style={{transform:`translateX(-${scrollY/3}px)`,opacity:1-scrollY/scale_300}}/>
                                    <p className="text"  style={{transform:`translateY(-${scrollY/3}px)`,opacity:1-scrollY/scale_300}}>智造价值与企业家同行</p>
                                    <span className={`arrow right`}  onClick={e=>this.swiperComp.swiper.slideNext(500)}  style={{transform:`translateX(${scrollY/3}px)`,opacity:1-scrollY/scale_300}}/>
                                </h1>
                            </div>
                        </div>


                        <MouseAnimation/>
                        {/*<div className="switchs">
                            {
                                banners.map((item,index)=>{
                                    return (<div className={`dot ${swiperIndex===index?'on':''}`}/>);
                                })
                            }
                        </div>*/}
                    </div>
                </div>
                <Main top={false} className={`slide-2 position-sticky slide-block`}  style={(e=>{
                    let style={};
                    let rect=this.getRefRect(refs.slide2);
                    let trigger=0;
                    let twh=this.toScale(wh);
                    let v=this.getProportion(rect.bottom-trigger,twh);
                    style.backgroundPosition=`center ${-(twh-v*twh)}px`;
                    return style;
                })()}>
                    <div className="layer-bg  flex v center " style={(e=>{
                        let style={};
                        let rect=this.getRefRect(refs.slide2);
                        let trigger=0;
                        let v=this.getProportion(rect.bottom-trigger,scale_100);
                        style.backgroundColor=`rgba(0,0,0,${v*0.2})`;
                        return style;
                    })()}>
                        <div className={`main-content-box`}  ref={ref=>refs.slide2=ref} >
                            <h1 className="title"  style={(e=>{
                                let style={};
                                let rect=this.getRefRect(refs.slide2);
                                let trigger=wh/4;
                                let v=this.getProportion(rect.bottom-trigger,scale_100);
                                style.opacity=v;
                                //style.transform=`translateY(${(100-(v)*100).toFixed(0)}px)`
                                return style;
                            })()}>关于我们</h1>
                            {/*<ul className="flex h data-list">
                                <li className="data-item"  style={(e=>{
                                    let style={};
                                    let rect=this.getRefRect(refs.slide2);
                                    let trigger=wh/4+this.toScale(0);
                                    let v=this.getProportion(rect.bottom-trigger,scale_300);
                                    style.opacity=v;
                                    style.transform=`translateX(${(scale_100-(v)*scale_100).toFixed(0)}px)`
                                    return style;
                                })()} >
                                    <h1 className="data-number">{totalCount(400,this.toScale(100))} <span className={`unit`}>亿</span></h1>
                                    <p className="info">
                                        管理资产规模<br/>
                                        突破400亿人民币
                                    </p>
                                </li>
                                <li className="data-item"  style={(e=>{
                                    let style={};
                                    let rect=this.getRefRect(refs.slide2);
                                    let trigger=wh/4+this.toScale(50);
                                    let v=this.getProportion(rect.bottom-trigger,scale_300);
                                    style.opacity=v;
                                    style.transform=`translateX(${(scale_100-(v)*scale_100).toFixed(0)}px)`
                                    return style;
                                })()} >
                                    <h1 className="data-number">{totalCount(100,this.toScale(150))} <span className={`unit`}>家</span></h1>
                                    <p className="info">
                                        团队累计<br/>
                                        投资企业超过100家
                                    </p>
                                </li>
                                <li className="data-item"  style={(e=>{
                                    let style={};
                                    let rect=this.getRefRect(refs.slide2);
                                    let trigger=wh/4+scale_100;
                                    let v=this.getProportion(rect.bottom-trigger,scale_300);
                                    style.opacity=v;
                                    style.transform=`translateX(${(scale_100-(v)*scale_100).toFixed(0)}px)`
                                    return style;
                                })()} >
                                    <h1 className="data-number">{totalCount(20,this.toScale(200))} <span className={`unit`}>家</span></h1>
                                    <p className="info">
                                        通过近20家企业<br/>
                                        完成IPO或并购实现退出
                                    </p>
                                </li>
                            </ul>*/}
                            <div className="info-bottom">
                                <p style={(e=>{
                                    let style={};
                                    let rect=this.getRefRect(refs.slide2);
                                    let trigger=wh/4+scale_100;
                                    let v=this.getProportion(rect.bottom-trigger,scale_300);
                                    style.opacity=v;
                                    style.transform=`translateY(${(scale_100-(v)*scale_100).toFixed(0)}px)`
                                    return style;
                                })()}>
                                    光智资本由光大控股联合特斯联共同发起设立，由光大控股新经济迭代而成。依托光大控股千亿金融央企资源，特斯联全球领先的人工智能物联网技术，及二者庞大的产业生态圈层。光智资本围绕“科技+”整体投资策略，覆盖高端制造、绿色科技、企业服务、新消费、集成电路等领域发掘优质项目创造持久价值。
                                </p>
                                <br/>
                                <br/>
                                <p style={(e=>{
                                    let style={};
                                    let rect=this.getRefRect(refs.slide2);
                                    let trigger=wh/4+this.toScale(150);
                                    let v=this.getProportion(rect.bottom-trigger,scale_300);
                                    style.opacity=v;
                                    style.transform=`translateY(${(scale_100-(v)*scale_100).toFixed(0)}px)`
                                    return style;
                                })()}>
                                    团队过往资产管理规模已突破400亿元，累计投资超过100家企业，同时在项目的获取和投资上，先后通过近20家企业完成IPO或并购实现退出。
                                </p>
                                <br/>
                                <br/>
                                <p style={(e=>{
                                    let style={};
                                    let rect=this.getRefRect(refs.slide2);
                                    let trigger=wh/4+scale_200;
                                    let v=this.getProportion(rect.bottom-trigger,scale_300);
                                    style.opacity=v;
                                    style.transform=`translateY(${(scale_100-(v)*scale_100).toFixed(0)}px)`
                                    return style;
                                })()}>
                                    过往的4年中，团队凭借严谨的投研体系及专业的资产管理能力，先后投出了诸多头部项目：商汤科技、特斯联、寒武纪、第四范式、奇安信、京东物流、蔚来汽车、小鹏汽车、美团点评、爱奇艺、网易云音乐、马上金融、银联商务、Nreal、Wish、三人行传媒、元气森林、每日优鲜、T11、云迹科技、编程猫等。
                                </p>
                                <br/>
                            </div>
                            <div className="btns flex h main-center">
                                <div className={` flex h `}>
                                    <NavLink to={'/team'}>
                                        <div className={`btn animate__animated ${this.getRefRect(refs.btn1).bottom>0?'animate__bounceInLeft':''}`} ref={ref=>refs.btn1=ref}>投资团队</div>
                                    </NavLink>
                                    <NavLink to={`/investment`}>
                                        <div className={`btn no-margin animate__animated ${this.getRefRect(refs.btn2).bottom>0?'animate__bounceInRight':''}`} ref={ref=>refs.btn2=ref}>投资案例</div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </Main>
                <Main top={false} className={`slide-3 slide-block position-sticky  flex v center`} style={(e=>{
                    let style={};
                    let rect=this.getRefRect(refs.slide3);
                    let trigger=0;
                    let twh=this.toScale(wh);
                    let v=this.getProportion(rect.bottom-trigger,twh);
                    style.backgroundSize=`auto ${300-v*160}%`
                    return style;
                })()}>
                    <div className="layer-bg" style={(e=>{
                        let style={};
                        let rect=this.getRefRect(refs.slide3);
                        let trigger=wh/3;
                        let v=this.getProportion(rect.bottom-trigger,scale_100);
                        style.backgroundColor=`rgba(0,0,0,${v*0.6})`;
                        return style;
                    })()}/>
                    <div className={`main-content-box`}  ref={ref=>refs.slide3=ref} >
                        <h1 className="title"  style={(e=>{
                            let style={};
                            let rect=this.getRefRect(refs.slide3);
                            let trigger=wh/3;
                            let v=this.getProportion(rect.bottom-trigger,scale_100);
                            style.opacity=v;
                            style.transform=`translateY(${(scale_100-(v)*scale_100).toFixed(0)}px)`
                            return style;
                        })()}>新闻资讯</h1>
                        <div className="new-list flex space-between">
                            {
                                newsList.map((item,index)=>(
                                    <NavLink to={`/content/news/${item.id}`}>
                                        <div className="item" style={(e=>{
                                            let style={};
                                            let rect=this.getRefRect(refs.slide3);
                                            let trigger=wh/3+scale_100+this.toScale(50*index);
                                            let v=this.getProportion(rect.bottom-trigger,scale_300);
                                            style.opacity=v;
                                            style.transform=`rotateY(${60-v*60}deg)`
                                            return style;
                                        })()}>
                                            <img className="img" src={item.cover}/>
                                            <h1 className="sub-title" dangerouslySetInnerHTML={{__html:item.recommended_title||item.title}}></h1>
                                            <p className="text"  dangerouslySetInnerHTML={{__html:item.desc}}></p>
                                            <p className="date">
                                                {moment(item.date).format("DD")}<br/>
                                                <span>{moment(item.date).format("YYYY-MM")}</span>
                                            </p>
                                        </div>
                                    </NavLink>
                                ))
                            }
                            {
                                Array.from({length:newsList.length%4},data=>(<div className={`item`}/>))
                            }
                        </div>
                        <div className="btns flex center">
                            <NavLink to={'/news'}><div className={`btn animate__animated  ${this.getRefRect(refs.btn3).bottom>0?'animate__heartBeat':''} `} ref={ref=>refs.btn3=ref}>查看更多</div></NavLink>
                        </div>
                    </div>
                </Main>
                <Footer/>
            </div>
        );
    }
}

