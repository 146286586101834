import React, {Component} from 'react';
import CustomComponent
    from "../public/CustomComponent";
import '../css/index.scss';
import Header from "../public/Header";
import Swiper from "../public/Swiper";
import Main from "../public/Main";
import {NavLink} from "react-router-dom";
import "../css/page-news.scss"
import Footer from "../public/Footer";
import MouseAnimation from "../public/MouseAnimation";
import {news} from "../public/G";
import ToggleVisible from "../public/ToggleVisible";
import ajax from "../public/Ajax";
import moment from "moment/moment";
const refs={};
export default class News extends CustomComponent {
    state={
        newsList:[],
        moreBtn:true,
        pageInfo:{total:0},
        pageIndex:1
    };
    componentDidMount() {
        super.componentDidMount();
        this.forceUpdate();
        this.getList();


    }
    async getList(pageIndex=1){
        let newList=this.state.newsList;
        let result=await ajax.post('news-list',{pageIndex:pageIndex,pageSize:5});
        this.setState({newsList:newList.concat(result.data.list||[]),pageInfo:result.data.pageInfo,pageIndex});
    }
    async loadMore(){
        let pageIndex=this.state.pageIndex;
        await this.getList(++pageIndex)
    }
    render() {
        let {innerHeight:wh}=window;
        let {newsList,moreBtn,pageInfo}=this.state;
        let bannerEff=(e=>{
            let style={};
            let rect=this.getRefRect(refs.slide2);
            let trigger=0;
            let twh=wh;
            let v=this.getProportion(rect.top-trigger,twh);
            style.backgroundPosition=`center ${((v*this.toScale(300)))-this.toScale(300)}px`;
            style.opacity=v;
            return style;
        })();
        return (
            <div className={`page-news`}>
                <Header status={0} index={4}/>
                <div className="main-content body-main position-sticky slide-block">
                    <div className="banner">
                        <div className="image-item"  style={{backgroundPosition:bannerEff.backgroundPosition}}>
                            <div className="layer-bg-black "/>
                            <div className="main-content-box">
                                <div className="banner-title animate__animated animate__backInDown">
                                    <p className="big"   style={{transform:`translateY(${(1-bannerEff.opacity)*this.toScale(-300)}px)`,opacity:bannerEff.opacity}}>新闻动态</p>
                                    <p className="sub"   style={{transform:`translateY(${(1-bannerEff.opacity)*this.toScale(-400)}px)`,opacity:bannerEff.opacity}}>News</p>
                                </div>
                            </div>
                        </div>
                        <MouseAnimation/>
                    </div>
                </div>
                <Main top={false} className={`slide-2  position-sticky slide-block flex h`} setRef={ref=>{refs.slide2=ref;}}>
                    <div className="main-content-box list">
                        <h1 className="sub-title">NEWS</h1>
                    </div>

                    <ul className="list-items">
                        {
                            newsList.map((item,index)=>{

                                let key=`item-${index}`
                                return (
                                    <li className="item-li"  ref={ref=>refs[key]=ref} style={(e=>{
                                        let style={};
                                        let rect=this.getRefRect(refs[key]);
                                        let trigger=0;
                                        let v=this.getProportion(rect.bottom-trigger,this.toScale(wh/2));
                                        style.transform=`translateY(${(1-v)*this.toScale(200)}px) scale(${0.6+v*0.4})`;
                                        return style;
                                    })()}>
                                        <NavLink to={`/content/news/${item.id}`}>
                                            <div className="item main-content-box  flex space-between h">
                                                <div className="image" style={{backgroundImage:`url(${item.cover})`}}/>
                                                <div className="infos">
                                                    <h1 className="new-title"  dangerouslySetInnerHTML={{__html:item.title}}></h1>
                                                    <p className="date"  dangerouslySetInnerHTML={{__html:moment(item.date).format("YYYY-MM-DD")}}></p>
                                                    <p className="desc"  dangerouslySetInnerHTML={{__html:item.desc}}></p>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <ToggleVisible visible={pageInfo.total>newsList.length}>
                        <div className="btns flex center">
                            <div className="btn" onClick={e=>this.loadMore()}>查看更多</div>
                        </div>
                    </ToggleVisible>
                </Main>
                <Footer/>
            </div>
        );
    }
}
