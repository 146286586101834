import React
    from 'react';
import CustomComponent
    from "../public/CustomComponent";
import Header from "../public/Header";
import Footer from "../public/Footer";
import Main from "../public/Main";
import "../css/page-content.scss"
import {news, teams} from "../public/G";
import {NavLink} from "react-router-dom";
import {PUBLIC_URL} from "../public/G"
import ToggleVisible from "../public/ToggleVisible";
import ajax from "../public/Ajax";

const refs={};
export default class Content extends CustomComponent {
    state={
        content:"",
        newsList:[],
        style:{},
        current:{},
        type:"news"
    };
    componentDidMount() {
        super.componentDidMount();
        this.onInit();

    }
    onInit(){
        let {match}=this.props;
        let {id,type}=match.params;
        let data={};
        if(match.params.type==='teams'){
            data=teams;
            this.getTeams(id);
        }else if(match.params.type==='news'){
            data=news;
            this.getNew(id);
        }
        //let current=data.find(item=>item.id==match.params.id);
        this.onResize();
        this.setState({type:match.params.type});
        window.addEventListener('resize',this.onResize);
        ajax.post('news-list',{pageSize:5,pageIndex:1}).then((result)=>{
            this.setState({newsList:result.data.list});
        })
    }

    getTeams(id){
        ajax.post('team-one',{id}).then((result)=>{
            this.setState({current:result.data});
        })
    }
    getNew(id){
        ajax.post('news-one',{id}).then((result)=>{
            this.setState({current:result.data});
        })
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize',this.onResize)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let {match}=this.props;
        if(match.params.type!==prevProps.match.params.type||match.params.id!==prevProps.match.params.id){
            this.onInit();
        }
    }

    onResize=()=>{
        let recommendedRect=this.getRefRect(refs.recommended)
        this.setState({recommendedRect});
    }

    onBack(){
        this.props.history.goBack();
    }
    scroll(scrollY){
        let {innerHeight:wh}=window;
        let {content,newsList,recommendedRect}=this.state;
        let currentRect=this.getRefRect(refs.recommended);
        let footerRect=this.getRefRect(refs.footer?.footerRef);
        let style={};
        if(footerRect.top<wh){
            style={};
        }else if(scrollY>recommendedRect.top){
            style.position="fixed";
            style.left=recommendedRect.left;
            style.top="100px";
        }

        this.setState({style})
    }
    render() {
        let {current,newsList,recommendedRect,style,type}=this.state;
        let {pc}=this.props;
        return (
            <div className={`page-content`}>
                <Header status={0} index={5}/>
                <Main className={``}>
                    <div className="main-content-box">
                        <div className="back" onClick={e=>this.onBack()}>
                            <img src={`${PUBLIC_URL}/images/back-icon.svg`} className={`icon`}/>
                        </div>
                        <div className="flex h space-between">

                            <div className="content-html">
                                <ToggleVisible visible={type==='news'}>
                                    <div className="title">
                                        <h1 dangerouslySetInnerHTML={{__html:current.title}}></h1>
                                        <p className="date">{current.date}</p>
                                    </div>
                                </ToggleVisible>
                                <div dangerouslySetInnerHTML={{__html:current.content}}></div>
                            </div>

                            <div className="recommended " style={style}  ref={ref=>refs.recommended=ref} >
                                <h1 className="title"><img src={`${PUBLIC_URL}/images/icon0.svg`} className={`icon`}/></h1>
                                <ul className="list">
                                    {
                                        newsList.map((item,index)=>{
                                            if(item.id==current.id){
                                                return (
                                                    <NavLink to={`/content/news/${item.id}`}>
                                                        <li className="item first flex h space-between by-center" >
                                                            <p className="no-image" style={{backgroundImage:`url(${item.cover})`}}>
                                                                <span className="no">{index+1}</span>
                                                            </p>
                                                            <div className="content-info flex v space-between">
                                                                <p className="desc">{(item.desc||"").substr(0,24)}...</p>
                                                                <p className="date">{item.date}</p>
                                                            </div>
                                                        </li>
                                                    </NavLink>
                                                )
                                            }else {
                                                return  (
                                                    <NavLink to={`/content/news/${item.id}`}>
                                                        <li className="item flex h space-between">
                                                            <p className="no">{index+1}</p>
                                                            <div className="content-info">
                                                                <p className="desc">{(item.desc||"").substr(0,30)}...</p>
                                                            </div>
                                                        </li>
                                                    </NavLink>
                                                )
                                            }
                                        })
                                    }


                                </ul>
                                <div className="more-btns flex v">
                                    <NavLink to={`/news`}>
                                        <div className="more-btn flex center"><span>查看更多</span></div>
                                    </NavLink>
                                    <div className="look-btn flex h space-between by-center"><img src={`${PUBLIC_URL}/images/icon1.svg`} className={`icon`}/></div>
                                    <div className="look-btn flex h space-between by-center"><img src={`${PUBLIC_URL}/images/icon2.svg`}  className={`icon`}/></div>
                                    <div className="si-btn"><img src={`${PUBLIC_URL}/images/icon4.svg`}/></div>
                                    <div className="si-btn back" onClick={e=>window.scrollTo(0,0)}><img src={`${PUBLIC_URL}/images/icon5.svg`}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Main>
                <Footer white={false} ref={ref=>refs.footer=ref}/>
            </div>
        );
    }
}