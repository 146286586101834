import React, {Component} from 'react';
import CustomComponent
    from "../../public/CustomComponent";
import "../../css/mobile/page-content-mobile.scss"
import {news, PUBLIC_URL, teams} from "../../public/G";
import Header from "../../public/mobile/Header";
import MobileMain from "../../public/mobile/MobileMain";
import ToggleVisible from "../../public/ToggleVisible";
import ajax from "../../public/Ajax";

export default class Content extends CustomComponent{
    state={
        current:{},
        type:"news"
    };
    componentDidMount() {
        super.componentDidMount();
        this.onInit()
    }
    onBack(){
        this.props.history.goBack();
    }
    onInit(){
        let {match}=this.props;
        let {id,type}=match.params;
        let data={};
        if(match.params.type==='teams'){
            data=teams;
            this.getTeams(id);
        }else if(match.params.type==='news'){
            data=news;
            this.getNew(id);
        }
        this.setState({type:match.params.type});
        window.addEventListener('resize',this.onResize);
    }
    getTeams(id){
        ajax.post('team-one',{id}).then((result)=>{
            this.setState({current:result.data});
        })
    }
    getNew(id){
        ajax.post('news-one',{id}).then((result)=>{
            this.setState({current:result.data});
        })
    }
    render() {
        let {current,type}=this.state;
        return (
            <div  className={`page-mobile page-content-mobile`}>
                <Header pageId={``}/>
                <MobileMain >
                    <div className="back" onClick={e=>this.onBack()}>
                        <img src={`${PUBLIC_URL}/images/back-icon.svg`} className={`icon`}/>
                    </div>
                    <ToggleVisible visible={type==='news'}>
                        <div className="content-box">
                            <div className="title">
                                <h1 dangerouslySetInnerHTML={{__html:current.title}}></h1>
                                <p className="date">{current.date}</p>
                            </div>
                            <div className={`content-html`} dangerouslySetInnerHTML={{__html:current.content_mobile||current.content}}/>
                        </div>
                    </ToggleVisible>
                    <ToggleVisible visible={type==='teams'}>
                        <div className="content-box teams">
                            <div className={`content-html`} dangerouslySetInnerHTML={{__html:current.content_mobile||current.content}}/>
                        </div>
                    </ToggleVisible>
                </MobileMain>
            </div>
        )
    }
}