import React, {Component} from 'react';
import CustomComponent
    from "../public/CustomComponent";
import '../css/index.scss';
import Header from "../public/Header";
import Main from "../public/Main";
import '../css/page-auto.scss'
import "animate.css"
import CountUp from 'react-countup';
import Footer from "../public/Footer";
import MouseAnimation from "../public/MouseAnimation";
import {PUBLIC_URL} from "../public/G"
let refs={};
export default class About extends CustomComponent {
    componentDidMount() {
        super.componentDidMount();
        this.forceUpdate();
    }

    render() {
        let {innerHeight:wh}=window;
        let bannerEff=(e=>{
            let style={};
            let rect=this.getRefRect(refs.slide2);
            let trigger=0;
            let twh=wh;
            let v=this.getProportion(rect.top-trigger,twh);
            style.backgroundPosition=`center ${((v*this.toScale(100)))-this.toScale(300)}px`;
            style.opacity=v;
            return style;
        })();
        let slide2Eff=((options={})=>{
            let style={};
            let rect=this.getRefRect(options.ref);
            let trigger=0;
            let v=this.getProportion(rect.bottom-trigger,wh/1.5);
            style.opacity=v;
            style.transform=`translateX(${((1-v)*50*options.d).toFixed(0)}%)`
            return style;
        });
        return (
            <div className={`page-auto`}>
                <Header status={0} index={1}/>
                <div className="main-content body-main position-sticky slide-block">
                    <div className="banner">
                        <div className="image-item"  style={{backgroundPosition:bannerEff.backgroundPosition}}>
                            <div className="layer-bg-baseMap animate__animated animate__fadeIn animate__slower  " style={{backgroundPosition:bannerEff.backgroundPosition,opacity:bannerEff.opacity}}/>
                            <div className="layer-bg-black "/>
                            <div className="data-info flex h space-between" style={{transform:`translateY(${(1-bannerEff.opacity)*this.toScale(-200)}px)`,opacity:bannerEff.opacity}}>
                                <div className="auto-title animate__animated animate__backInDown">
                                    <p className="big">光智资本</p>
                                    <p className="sub">ET Capital</p>
                                </div>
                                <div className="data-list flex h space-between">
                                    <div className="data-item">
                                        <p className="number"><CountUp end={400} duration={0.5} delay={0}/><span className={`unit`}>亿</span></p>
                                        <p className="desc">
                                            管理资产规模<br/>
                                            突破400亿人民币
                                        </p>
                                    </div>
                                    <div className="data-item">
                                        <p className="number"><CountUp end={100} duration={0.5} delay={0.1}/><span className={`unit`}>家</span></p>
                                        <p className="desc">
                                            团队累计<br/>
                                            投资企业超过100家
                                        </p>
                                    </div>
                                    <div className="data-item">
                                        <p className="number"><CountUp end={20} duration={0.5} delay={0.2}/><span className={`unit`}>家</span></p>
                                        <p className="desc">
                                            通过近20家企业<br/>
                                            IPO或并购实现退出
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <MouseAnimation/>
                        </div>
                    </div>
                </div>
                <Main top={false} className={`slide-2  position-sticky slide-block flex h`} setRef={ref=>{refs.slide2=ref;}}>
                    <div className="text-box   flex center" style={slide2Eff({d:1,ref:refs.slide2})}>
                        <p className="tex width-540" >
                            光智资本由光大控股联合特斯联共同发起设立，由光大控股新经济迭代而成。依托光大控股千亿金融央企资源，特斯联全球领先的人工智能物联网技术，及二者庞大的产业生态圈层。光智资本围绕“科技+”整体投资策略，覆盖高端制造、绿色科技、企业服务、新消费、集成电路等领域发掘优质项目创造持久价值。<br/>
                            <br/>
                            团队过往资产管理规模已突破400亿元，累计投资超过100家企业，先后通过近20家企业完成IPO或并购实现退出。<br/>
                            <br/>
                            过往的4年中，团队凭借严谨的投研体系及专业的资产管理能力，先后投出了诸多头部项目：商汤科技、特斯联、寒武纪、第四范式、奇安信、京东物流、蔚来汽车、小鹏汽车、美团点评、爱奇艺、网易云音乐、马上金融、银联商务、Nreal、Wish、三人行传媒、元气森林、每日优鲜、T11、云迹科技、编程猫等。<br/>
                        </p>
                    </div>
                    <div className="image" style={{...slide2Eff(({d:-1,ref:refs.slide2})),backgroundImage:`url(${PUBLIC_URL}/images/page-about-block-1.jpg)`,opacity:1}}>
                        <div className="auto-title ">
                            <p className="big">公司简介</p>
                            <p className="sub">Our Company</p>

                        </div>
                    </div>
                </Main>
                <Main top={false} className={`slide-2 right  position-sticky slide-block flex h`} setRef={ref=>{refs.slide3=ref;}}>
                    <div className="image"  style={{...slide2Eff(({d:-1,ref:refs.slide3})),backgroundImage:`url(${PUBLIC_URL}/images/page-about-block-2.jpg)`,opacity:1}}>
                        <div className="auto-title ">
                            <p className="big">关注领域</p>
                            <p className="sub">Sector Focus</p>
                        </div>
                    </div>
                    <div className="text-box  flex center" style={slide2Eff({d:1,ref:refs.slide3})}>
                        <div className={`tex`}>
                            <p>围绕“科技+”整体投资策略</p>
                            <p className="img1"><img src={`${PUBLIC_URL}/images/page-about-block-2-img.svg`}/></p>
                        </div>
                    </div>
                </Main>
                <Main top={false} className={`slide-2 top-auto  position-sticky slide-block flex h`} setRef={ref=>{refs.slide4=ref;}}>
                    <div className="text-box  flex center" style={slide2Eff({d:1,ref:refs.slide4})}>
                        <p className="tex flex h">
                            <span className={`sp1`} >
                                福布斯<br/>
                                36氪<br/>
                                36氪<br/>
                                36氪<br/>
                                界面<br/>
                                福布斯<br/>
                                艾媒咨询<br/>
                                钛媒体<br/>
                                钛媒体<br/>
                                中国证券报<br/>
                                21世纪<br/>
                                36氪<br/>
                                36氪<br/>
                                ……<br/>
                            </span>
                            <span  className={`sp2`}>
                                2021 年度中国最佳创投人 TOP 100<br/>
                                2021 年中国最受创业者欢迎投资人 TOP 100<br/>
                                2021 中国前沿科技领域投资人TOP 20<br/>
                                2021 年最受中国创业者欢迎私募股权投资机构 TOP 50<br/>
                                2021年度智慧城市影响力人物<br/>
                                2020 年度中国最佳创投人 TOP 100<br/>
                                2020 年度中国最佳投资人奖项<br/>
                                2020 Edge Awards 全球创新评选 TOP 50 <br/>
                                2020 Edge 50 私募股权榜单<br/>
                                2020 金牛大数据和人工智能领域投资机构<br/>
                                2020 私募股权投资机构 TOP 20<br/>
                                2020 年度中国最受创业者欢迎私募股权投资机构 TOP 50<br/>
                                2020 年度中国最受LP认可私募投资机构 TOP 30<br/>
                                ……
                            </span>
                        </p>
                    </div>
                    <div className="image"  style={{...slide2Eff(({d:-1,ref:refs.slide4})),backgroundImage:`url(${PUBLIC_URL}/images/page-about-block-3.jpg)`,opacity:1}}>
                        <div className="auto-title ">
                            <p className="big">获得荣誉</p>
                            <p className="sub">Awards</p>
                        </div>
                    </div>
                </Main>
                <Footer/>
            </div>
        );
    }
}