import React, {Component} from 'react';
import CustomComponent
    from "../../public/CustomComponent";
import PageMobileContent from "../../public/mobile/PageMobileContent";
import "../../css/mobile/page-contact-mobile.scss"
import ToggleVisible from "../../public/ToggleVisible";
import {NavLink} from "react-router-dom";
import {PUBLIC_URL} from "../../public/G";
const refs={};
export default class Contact extends CustomComponent {
    state={};
    render() {
        let {}=this.state;
        let {innerHeight:wh}=window;
        let effect=((ref,trigger)=>{
            let styles={};
            let v=this.contentBlocksEffect(ref,trigger);
            styles.opacity=v;
            styles.transform=`translateY(${(1-v)*this.toScale(100)}px)`;
            return styles;
        });
        return (
            <PageMobileContent className={`page-contact-mobile`} bg={`${PUBLIC_URL}/images/mobile/contact-bg.jpg`} id={`contact`} bottomBtns={false}>
                <div className="content-box" ref={ref=>refs.cb1=ref} >
                    <div style={effect(refs.cb1)}>
                        <h1 className="page-title">联系我们</h1>
                        <p className="page-desc">Contact Us</p>
                    </div>
                    <div className={`content-blocks-box`}>
                        <div className="content-block"  style={effect(refs.cb1,240)}>
                            <p className="page-subdesc margin-top20">提交商业计划书 <a href="mailto:BP@guangzhicapital.com">BP@guangzhicapital.com</a></p>
                            <p className="page-subdesc margin-top20">工作机会请投递   <a href="mailto:HR@guangzhicapital.com">HR@guangzhicapital.com</a></p>
                        </div>
                        <div className="content-block" style={effect(refs.cb1,360)}>
                            <p className="page-subtitle ">光智资本北京办公室</p>
                            <br/>
                            <p className="page-subdesc margin-top20">北京市朝阳区新源南路8号启皓北京西塔9层  100027</p>
                            <p className="page-subdesc margin-top20">www.guangzhicapital.com</p>
                        </div>
                        <div className="content-block flex h flex-row qr-block" style={effect(refs.cb1,480)}>
                            <p className="qr"><img src={`${PUBLIC_URL}/images/qr.jpg`}/></p>
                            <p className="page-subdesc">
                                扫码关注<br/>
                                光智最新动态
                            </p>
                        </div>
                    </div>
                </div>
            </PageMobileContent>
        )
    }
}