import React
    from 'react';
import CustomComponent
    from "./CustomComponent";
import ToggleVisible
    from "./ToggleVisible";

export default class Main extends CustomComponent {
    render() {
        let {className,top=true,style={},setRef}=this.props;
        return (
            <div className={`main-content ${top?'body-main':''} ${className||''}`} ref={setRef}  style={style}>
                {this.props.children}
            </div>
        );
    }
}
