import React, {Component} from 'react';
import CustomComponent
    from "../../public/CustomComponent";
import PageMobileContent from "../../public/mobile/PageMobileContent";
import "../../css/mobile/page-team-mobile.scss"
import ToggleVisible from "../../public/ToggleVisible";
import {NavLink} from "react-router-dom";
import {news, PUBLIC_URL,teams} from "../../public/G";
import MobileMain from "../../public/mobile/MobileMain";
import Header from "../../public/mobile/Header";
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss';
import ajax from "../../public/Ajax"; // Pagination module
const refs={};
export default class Team extends CustomComponent {
    state={
        list:[]
    };
    componentDidMount() {
        super.componentDidMount();
        this.forceUpdate()
        ajax.post('team-list').then((result)=>{
            this.setState({list:result.data});
        })
    }

    render() {
        let {list}=this.state;
        let {innerHeight:wh}=window;
        return (
            <div className={`page-mobile page-team-mobile`}>
                <Header pageId={``}/>
                <MobileMain>
                    <div className={`swiper-box`}>
                        {
                            list.map((item,index)=>{
                                let ms=index*300;
                                return (
                                    <div className={`slide-item`} style={{backgroundImage:`url(${item.cover_mobile})`}}>
                                        <div className="infos flex h">
                                            <div className={`name animate__animated animate__bounceInDown`} style={{animationDelay:`${ms+500}ms`}}>{item.name}</div>
                                            <div className={`info animate__animated animate__flipInX`} style={{animationDelay:`${ms+500+200}ms`}}>
                                                {item.desc2}
                                            </div>
                                            <div className={`info animate__animated animate__flipInX`} style={{animationDelay:`${ms+500+100}ms`}}>
                                                {item.desc1}
                                            </div>
                                        </div>
                                        <NavLink to={`/content/teams/${item.id}`}>
                                            <div className="layer-click"/>
                                        </NavLink>
                                    </div>
                                )
                            })
                        }
                    </div>
                </MobileMain>
            </div>
        )
    }
}