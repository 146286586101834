import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {isPC,isLandscape,onResizeEventName} from "./public/fn";



let app=null;
const onresize=(e=>{
    let {innerWidth}=window;
    let size=20;
    //document.documentElement.style.fontSize=`${innerWidth/1280*size}px`;
    if(isPC()){
        window.$appScale=innerWidth/1480;
        window.$appScale=window.$appScale>1?1:window.$appScale;
        document.documentElement.style.fontSize=`${window.$appScale*size}px`;
    }else {
        document.documentElement.style.fontSize=`${innerWidth/750*size}px`;
    }
    // if(isPC()){
    //     document.documentElement.style.fontSize=`${innerWidth/1680*size}px`;
    //
    // }else {
    //     if(!isLandscape()){
    //         document.documentElement.style.fontSize=`${innerWidth/750*size}px`;
    //     }else{
    //         document.documentElement.style.fontSize=`${innerWidth/1680*size}px`;
    //     }
    // }
    app&&app.forceUpdate();
})

// window.addEventListener("onorientationchange", onresize, false);
// window.addEventListener('resize',onresize);
window.addEventListener(onResizeEventName, e=>setTimeout(onresize,300), false);
onresize();
ReactDOM.render(
  <App ref={ref=>app=ref}/>,
  document.getElementById('root')
);
