import "babel-es6-polyfill";
import React, {Component} from 'react';
import './App.scss';
import "./css/style.scss";

import {
    HashRouter as Router,
    Route,
    Link,
    Switch
} from "react-router-dom";
import CustomComponent from "./public/CustomComponent";
import Index from "./pages/Index";
import * as qs from "qs";
import LocalStorage, {UserKey} from "./public/LocalStorage";
import {isLandscape,isPC} from "./public/fn"
import About from "./pages/About";
import Team from "./pages/Team";
import News from "./pages/News";
import Contact from "./pages/Contact";
import Investment from "./pages/Investment";
import Content from "./pages/Content";
import IndexMobile from "./pages/mobile/Index";
import MobilePage from "./pages/mobile/MobilePage";
import MobileTeam from "./pages/mobile/Team";
import MobileContent from "./pages/mobile/Content";

function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            render={props => {
                //检查是否已经loading 没有的话就需要先加在
               /* if ([Index, Dev].includes(route.component) && !Loading.loading) {
                    
                    let {history, location} = props;
                    console.log(props, 'location')
                    history.replace({
                        pathname: "/loading",
                        search: location.search
                    });
                    return null;
                }*/
                // pass the sub-routes down to keep nesting
                if(isPC()){
                    return <route.component {...props} pc={true}  timeStamp={Date.now()} routes={route.routes} name={route.name}/>
                }else if(route.mobileComponent){
                    return  <route.mobileComponent {...props} pc={false}  timeStamp={Date.now()} routes={route.routes} name={route.name}/>
                }else {
                    console.log('未找到路由')
                }
            }}
        />
    );
}

class App extends CustomComponent {
    componentDidMount() {
        //获取query参数
        let params = qs.parse(window.location.search.replace("?", ''));
        LocalStorage.token = params.token;
        LocalStorage.no = params.no;
        LocalStorage.router = params.router || 'index';
        LocalStorage.stepName = params.stepName || LocalStorage.stepName || 'def';
        // console.log( LocalStorage.stepName ,' LocalStorage.stepName ')
    }

    render() {
        return (
            <div className={`${isPC() ? 'pc' : (isLandscape() ? 'landscape' : 'vertical')}`}>
                <Router>
                    <Switch>
                        <RouteWithSubRoutes path={'/index'} component={Index} mobileComponent={MobilePage}/>
                        <RouteWithSubRoutes path={'/about'} component={About} mobileComponent={MobilePage}/>
                        <RouteWithSubRoutes path={'/investment'} component={Investment} mobileComponent={MobilePage}/>
                        <RouteWithSubRoutes path={'/team'} component={Team} mobileComponent={MobileTeam}/>
                        <RouteWithSubRoutes path={'/news'} component={News} mobileComponent={MobilePage}/>
                        <RouteWithSubRoutes path={'/contact'} component={Contact} mobileComponent={MobilePage}/>
                        <RouteWithSubRoutes path={'/content/:type/:id'} component={Content} mobileComponent={MobileContent}/>
                        <RouteWithSubRoutes path="*" component={Index} mobileComponent={MobilePage}/>
                    </Switch>
                </Router>
            </div>

        );
    }
}

export default App;
