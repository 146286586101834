import React, {Component} from 'react';
import CustomComponent
    from "../public/CustomComponent";
import '../css/index.scss';
import Header from "../public/Header";
import Swiper from "../public/Swiper";
import Main from "../public/Main";
import CountUp from "react-countup";
import Footer from "../public/Footer";
import "../css/page-investment.scss"
import MouseAnimation from "../public/MouseAnimation";
import {PUBLIC_URL} from "../public/G"

const refs={};
export default class Investment extends CustomComponent {
    componentDidMount() {
        super.componentDidMount();
        this.forceUpdate();
    }
    render() {
        let {innerHeight:wh}=window;
        let bannerEff=(e=>{
            let style={};
            let rect=this.getRefRect(refs.slide2);
            let trigger=0;
            let twh=wh;
            let v=this.getProportion(rect.top-trigger,twh);
            style.backgroundPosition=`center ${((v*this.toScale(300)))-this.toScale(300)}px`;
            style.opacity=v;
            return style;
        })();
        const fields = [
            {name:"人工智能"},
            {name:"绿色科技"},
            {name:"金融科技"},
            {name:"新消费"},
        ];
        return (
            <div className={`page-investment`}>
                <Header status={0} index={2}/>
                <div className="main-content body-main position-sticky slide-block">
                    <div className="banner">
                        <div className="image-item"  style={{backgroundPosition:bannerEff.backgroundPosition}}>
                            <div className="layer-bg-black "/>
                            <div className="main-content-box">
                                <div className="banner-title animate__animated animate__backInDown">
                                    <p className="big"   style={{transform:`translateY(${(1-bannerEff.opacity)*this.toScale(-300)}px)`,opacity:bannerEff.opacity}}>投资案例</p>
                                    <p className="sub"   style={{transform:`translateY(${(1-bannerEff.opacity)*this.toScale(-400)}px)`,opacity:bannerEff.opacity}}>Investment Profile</p>
                                </div>
                            </div>
                        </div>
                        <MouseAnimation/>
                    </div>
                </div>
                <Main top={false} className={`slide-2  position-sticky slide-block flex h`} setRef={ref=>{refs.slide2=ref;}}>
                    <div className="main-content-box  flex  list by-center">
                        <div className="">
                            <div className="sub-title" ref={ref=>refs.subtitle=ref}>部分被投企业展示</div>
                            <div className={`space-between flex list-items`}>
                                {Array.from({length:30},(e,index)=>{
                                    return (
                                        <div className="item"  style={(e=>{
                                            let style={};
                                            let rect=this.getRefRect(refs.subtitle);
                                            let trigger=index*this.toScale(12)+this.toScale(wh/5);
                                            let v=this.getProportion(rect.bottom-trigger,80);
                                            style.opacity=v;
                                            style.transform=`rotateY(${(1-v)*45}deg) scale(${v})`
                                            return style;
                                        })()}><img src={`${PUBLIC_URL}/images/investment-trademark${index+1}.png`}/></div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Main>
                <Footer/>
            </div>
        );
    }
}