import React, {Component} from 'react';
import CustomComponent
    from "../public/CustomComponent";
import '../css/index.scss';
import Header from "../public/Header";
import {NavLink} from "react-router-dom";
import Footer from "../public/Footer";
import "../css/page-team.scss"
import MouseAnimation from "../public/MouseAnimation";
import {teams} from "../public/G";
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss';
import ToggleVisible from "../public/ToggleVisible";
import ajax from "../public/Ajax"; // Pagination module
let options={
    slidesPerView: 3,
    spaceBetween: 0,
};
const refs={};
export default class Team extends CustomComponent {
    state={
        list:teams,
    }
    componentDidMount() {
        super.componentDidMount();
        let {list}=this.state;
        this.forceUpdate();
        ajax.post('team-list').then((result)=>{
            this.setState({list:result.data});
        })
    }

    render() {
        let {innerHeight:wh}=window;
        let bannerEff=(distance=>{
            let style={};
            let rect=this.getRefRect(refs.footer?.footerRef);
            let trigger=0;
            let twh=wh;
            let v=this.getProportion(rect.top-trigger,twh);
            style.transform=`translateY(${(1-v)*-this.toScale(200+distance)}px)`;
            style.opacity=v;
            return style;
        })
        let v=bannerEff(0).opacity;
        let list=this.state.list;
        return (
            <div className={`page-team`}>
                <Header status={0} index={3}/>
                <div className="main-content body-main banner-box position-sticky">
                    <div className="banner">
                        <div className={`main-content-box banner-items`}>
                            <Swiper
                                modules={[Navigation,Pagination]}
                                navigation={list.length>3}
                                pagination={{ clickable: true }}
                                spaceBetween={0}
                                slidesPerView={3}
                                onSlideChange={() => console.log('slide change')}
                                onSwiper={(swiper) => console.log(swiper)}
                            >
                                {
                                    list.map((item,index)=>{
                                        let ms=index*300;
                                        return (
                                            <SwiperSlide>
                                                <div className="image-item swiper-slide animate__animated animate__fadeInDown" index={index} style={{animationDelay:`${ms}ms`}} ref={ref=>{refs.imageItem=ref;item.ref=ref;}}>
                                                    <NavLink to={`/content/teams/${item.id}`}>
                                                        <div className={`image-item-content`}>
                                                            <div className={`image-item-image`} style={{backgroundImage:`url(${item.cover})`}}>

                                                            </div>
                                                            <div className="infos flex h">
                                                                <div className={`name ${v<1?'':'animate__animated'} animate__bounceInDown`} style={{animationDelay:`${ms+500}ms`,...bannerEff(-100)}}>{item.name}</div>
                                                                <div className={`info ${v<1?'':'animate__animated'} animate__flipInX`} style={{animationDelay:`${ms+500+100}ms`,...bannerEff(-50)}}>
                                                                    {item.desc1}
                                                                </div>
                                                                <div className={`info ${v<1?'':'animate__animated'} animate__flipInX`} style={{animationDelay:`${ms+500+200}ms`,...bannerEff(0)}}>
                                                                    {item.desc2}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </NavLink>

                                                </div>
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                           {/* <div  className={`flex h `} style={{width:`${(refs.imageItem?.clientWidth+2)*list.length}px`}}>
                                {
                                    list.map((item,index)=>{
                                        let ms=index*300;
                                        return (
                                            <div className="image-item swiper-slide animate__animated animate__fadeInDown" index={index} style={{animationDelay:`${ms}ms`}} ref={ref=>{refs.imageItem=ref;item.ref=ref;}}>
                                                <NavLink to={`/content/teams/${item.id}`}>
                                                    <div className={`image-item-content`}>
                                                        <div className={`image-item-image`} style={{backgroundImage:`url(${item.cover})`}}>

                                                        </div>
                                                        <div className="infos flex h">
                                                            <div className={`name ${v<1?'':'animate__animated'} animate__bounceInDown`} style={{animationDelay:`${ms+500}ms`,...bannerEff(-100)}}>{item.name}</div>
                                                            <div className={`info ${v<1?'':'animate__animated'} animate__flipInX`} style={{animationDelay:`${ms+500+100}ms`,...bannerEff(-50)}}>
                                                                {item.desc1}
                                                            </div>
                                                            <div className={`info ${v<1?'':'animate__animated'} animate__flipInX`} style={{animationDelay:`${ms+500+200}ms`,...bannerEff(0)}}>
                                                                {item.desc2}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </NavLink>

                                            </div>
                                        );
                                    })
                                }
                            </div>*/}
                            <h1 className={`title`} style={{display:'none'}}>
                                <span className={`arrow left`} onClick={e=>{this.slidePrev()}} style={{opacity:bannerEff(0).opacity}}/>
                                <span className={`arrow right`}  onClick={e=>this.slideNext()} style={{opacity:bannerEff(0).opacity}}/>
                            </h1>
                        </div>

                        <MouseAnimation/>
                    </div>
                </div>
                <Footer ref={ref=>refs.footer=ref}/>
            </div>
        );
    }
}