export const PUBLIC_URL="https://guangzhicapital-file.oss-cn-beijing.aliyuncs.com/website/pc";
//export const PUBLIC_URL="http://192.168.2.7:8084";

export const news=[
    {
        cover:`${PUBLIC_URL}/images/dev-images/new-cover1.png`,
        title:"艾渝连续三年入选福布斯“中国最佳创投人 TOP100”：要“雪中送炭”，更要“为虎添翼”",
        recommended_title:"艾渝连续三年入选福布斯“中国最佳创投人 TOP100”",
        desc:"2021年12月，福布斯“中国最佳创投人 TOP100”榜单发布，光智资本创始管理合伙人艾渝登榜，成为榜单中最年轻的几位投资人之一。这同时也是艾渝连续第三年入选该榜单……",
        date:"2021-12-28",
        indexrecommended:1,
        recommended:0,
        content:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-content1.png'>`,
        content_mobile:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-mobile-content1.png'>`,
        sort:7,
        id:1,
    },
    {
        cover:`${PUBLIC_URL}/images/dev-images/new-cover2.png`,
        title:"2021最受创业者欢迎投资人及投资机构出炉：艾渝率光智基金连中三元",
        recommended_title:"艾渝率光智基金获2021最受创业者欢迎投资人及机构",
        desc:"进入2021年，伴随着内外部环境的快速转换，市场关注热点几乎以周为单位迅速更新迭代，前沿科技、医疗大健康以及企业服务和供应链创新等赛道吸引了大量成熟创业者投身其中，并将迎来全新增长周期……",
        date:"2021-09-24",
        indexrecommended:1,
        content:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-content2.png'>`,
        content_mobile:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-mobile-content2.png'>`,
        id:2,
    },

    {
        cover:`${PUBLIC_URL}/images/dev-images/new-cover3.png`,
        title:"产业资本深耕城市智能化转型 光智系列基金再增20亿",
        desc:"3月29日,由光大控股与特斯联联合设立的“光大控股智能新经济系列基金”(以下简称光智系列基金)召开发布会,宣布联合德阳市政府再增20亿元人民币,设立光智基金3号,在十四五国家重要战略规划的指导下……",
        date:"2021-03-31",
        indexrecommended:1,
        content:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-content3.png'>`,
        content_mobile:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-mobile-content3.png'>`,
        id:3,
    },
    {
        cover:`${PUBLIC_URL}/images/dev-images/new-cover4.png`,
        title:"艾渝荣获2020年度中国最佳投资人奖项",
        desc:"近日，由全球领先的第三方数据分析机构艾媒咨询设立的2020新经济行业年度巅峰系列榜单在京发布，艾渝作为国内新经济领军人物荣获2020年度中国最佳投资人奖项……",
        date:"2021-01-28",
        indexrecommended:1,
        content:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-content4.png'>`,
        content_mobile:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-mobile-content4.png'>`,
        id:4,
    },
    {
        cover:`${PUBLIC_URL}/images/dev-images/new-cover5.png`,
        title:"艾渝登榜福布斯2020中国最佳创投人TOP100",
        desc:"12月28日，福布斯中国发布“2020年中国最佳创投人TOP100”榜单，艾渝作为中国新经济领军人物登榜并成为该榜单中最年轻的几位投资人之一。这也是继2019年艾渝入选该榜单后，再次获评福布斯中国年度最佳投资人称谓……",
        date:"2020-12-28",
        indexrecommended:0,
        recommended:1,
        content:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-content5.png'>`,
        content_mobile:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-mobile-content5.png'>`,
        sort:6,
        id:5,
    },
    {
        cover:`${PUBLIC_URL}/images/dev-images/new-cover6.png`,
        title:"产业资本抢滩新基建风口 100亿基金加码布局智能新经济",
        desc:"近期，新基建无疑成为万众瞩目的焦点，随着中央的深入部署，利好政策的密集落地，素有“聪明钱”之称的产业资本也正加快行动，推动以5G、人工智能、物联网、数据中心等为代表的新一代信息基础设施的建设……",
        date:"2020-08-07",
        indexrecommended:0,
        recommended:1,
        content:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-content6.png'>`,
        content_mobile:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-mobile-content6.png'>`,
        sort:8,
        id:6,
    },
    {
        cover:`${PUBLIC_URL}/images/dev-images/new-cover7.png`,
        title:"智能新经济时代来临 机构加速布局新基建",
        desc:"7月23日，在国务院新闻办举办的新闻发布会上，工信部副部长辛国斌表示，上半年新产业新业态显露生机。其中，信息技术与制造业加快融合，以人工智能、物联网、车联网等技术创新和产业应用步伐加快……",
        date:"2020-08-06",
        indexrecommended:0,
        recommended:1,
        content:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-content7.png'>`,
        content_mobile:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/new-mobile-content7.png'>`,
        sort:8,
        id:7,
    },
];
function escapeQuotes(str) {
    return str.replace(/"/g, '\\"').replace(/'/g, "\\'");
}
let v=news.map(n=>`INSERT INTO \`wopc\`.\`gz_news\` (\`title\`, \`recommended_title\`, \`desc\`, \`cover\`,
                                               \`content\`, \`content_mobile\`, \`sort\`, \`recommended\`, \`date\`)
             VALUES ( '${n.title}', '${n.recommended_title||''}', '${n.desc}', '${n.cover}', '${escapeQuotes(n.content)}', '${escapeQuotes(n.content_mobile)}', '${0}', ${n.indexrecommended||0}, '${n.date}');`).join('\n');
//console.log(v,'123')
export const teams=[
    {
        cover:`${PUBLIC_URL}/images/dev-images/team-head2.png`,
        cover_mobile:`${PUBLIC_URL}/images/dev-images/team-mobile-head2.jpg`,
        name:"安韧",
        desc1:"光智资本",
        desc2:"董事总经理",
        content:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/anren-content@2x.png'/>`,
        content_mobile:`<img style="width: 28.2rem;height: auto;" src='${PUBLIC_URL}/images/dev-images/anren-mobile-content.svg'/>`,
        id:2,
    },
    {
        cover:`${PUBLIC_URL}/images/dev-images/team-head1.png`,
        cover_mobile:`${PUBLIC_URL}/images/dev-images/team-mobile-head1.jpg`,
        name:"艾渝",
        desc1:"光智资本",
        desc2:"创始管理合伙人",
        content:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/aiyu-contet@2x.png'/>`,
        content_mobile:`<img style="width: 28.8rem;height: auto;" src='${PUBLIC_URL}/images/dev-images/aiyu-mobile-contet.svg'/>`,
        id:1,

    },
    {
        cover:`${PUBLIC_URL}/images/dev-images/team-head3.png`,
        cover_mobile:`${PUBLIC_URL}/images/dev-images/team-mobile-head3.jpg`,
        name:"郭振亚",
        desc1:"光智资本",
        desc2:"执行董事",
        content:`<img width='100%' src='${PUBLIC_URL}/images/dev-images/guozhenya-content@2x.png'/>`,
        content_mobile:`<img style="width: 30.3rem;height: auto;" src='${PUBLIC_URL}/images/dev-images/guozhenya-mobile-content.svg'/>`,
        id:3,
    },
];
let v2=teams.map(t=>`INSERT INTO \`wopc\`.\`gz_team\` (\`name\`, \`desc1\`, \`desc2\`, \`cover\`, \`cover_mobile\`, \`content\`, \`content_mobile\`, \`sort\`, \`date\`) VALUES ('${t.name}', '${t.desc1}', '${t.desc2}', '${t.cover}', '${t.cover_mobile}', '${escapeQuotes(t.content)}', '${escapeQuotes(t.content_mobile)}', 0, '2024-01-15 15:19:00');`).join('\n');
//console.log(v2,'124')
