export default {
    'get-html':{
        api:"/file/get-html"
    },
    'team-list': {
        api: '/team/get',
        type: 'post'
    },
    'team-one': {
        api: '/team/one',
        type: 'post'
    },
    'news-list': {
        api: '/news/get',
        type: 'post'
    },
    'news-one':{
        api: '/news/one',
        type: 'post'
    }
    // "interactionLog":{ //
    //     api:"/api/log"
    // },
}