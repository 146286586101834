import React
    from 'react';
import CustomComponent
    from "./CustomComponent";
import ToggleVisible
    from "./ToggleVisible";
import {NavLink} from "react-router-dom";
import Main from "./Main";

export default class Header extends CustomComponent {
    render() {
        let {status=1,index=0}=this.props;
       return (
           <Main className={`header-box`}>
               <div className={`header main-content-box ${status!==1?'white':''}`}>
                   <NavLink to={'/index'}>
                       <div className="logo"/>
                   </NavLink>


                   {/*<div className="function-item more"/>
                   <div className="function-item search"/>
                   <div className="language-item">
                       <a href="#">CN</a>/ <a href="#">EN</a>
                   </div>*/}
                   <NavLink to={'/contact'}>
                       <div className={`nav-item ${index===5?'on':''}`}>
                           联系我们
                       </div>
                   </NavLink>
                   <NavLink to={'/news'}>
                       <div className={`nav-item ${index===4?'on':''}`}>
                           新闻动态
                       </div>
                   </NavLink>
                  {/* <NavLink to={'/team'}>
                       <div className={`nav-item ${index===3?'on':''}`}>
                           管理团队
                       </div>
                   </NavLink>*/}
                   <NavLink to={'/investment'}>
                       <div className={`nav-item ${index===2?'on':''}`}>
                           投资案例
                       </div>
                   </NavLink>
                   <NavLink to={'/about'}>
                       <div className={`nav-item ${index===1?'on':''}`}>
                           关于光智
                       </div>
                   </NavLink>
                   <NavLink to={'/index'}>
                       <div className={`nav-item ${index===0?'on':''}`}>
                           首页
                       </div>
                   </NavLink>
               </div>
           </Main>
       );
    }
}
