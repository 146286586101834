import React
    from 'react';
import CustomComponent
    from './CustomComponent';
import ToggleVisible from "./ToggleVisible";
import {PUBLIC_URL} from "./G"

export default class  extends CustomComponent {
    render() {
        let {scrollY=0}=this.state||{};
        let opacity=(100-scrollY<0?0:100-scrollY)/100;
        return (
            <div className={`mouse-box`} style={{opacity}}>
                <img src={`${PUBLIC_URL}/images/mouse-icon.png`} className="mouse-icon animate__animated animate__heartBeat animate__infinite"/>
                <img src={`${PUBLIC_URL}/images/mouse-arrow-icon.png`} className="arrow animate__animated animate__fadeInDown animate__infinite"/>
            </div>
        );
    }
}