import React
    from 'react';
import CustomComponent
    from "../CustomComponent";
let  mask=null;
export default class MobileMain extends CustomComponent {
    render() {
        return (
            <div className={`main-content-mobile`}>
                {this.props.children}
                <div className="mask" onTouchMove={e=>{e.preventDefault()}}/>
            </div>
        )
    }
}