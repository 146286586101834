import React
    from 'react';
import CustomComponent
    from "../CustomComponent";
import {PUBLIC_URL} from "../G";
import {NavLink} from "react-router-dom";
export default class Header extends CustomComponent {
    state={
        menus:''
    };
    showMents(menus='on'){
        this.setState({menus});

        document.querySelectorAll('.mask').forEach(mask=>{
            if(menus==='on'){
                mask.classList.add('on')
            }else {
                mask.classList.remove('on')
            }
        });
    }
    render() {
        let {menus}=this.state;
        let {pageId}=this.props;
        return (
            <div className={`header-mobile flex h space-between by-center `}>
                <NavLink to={`/index`}><img className={`logo `} src={`${PUBLIC_URL}/images/logo.svg`} alt={`logo`}/></NavLink>
                <div className="flex h space-between right-btns">
                    <NavLink to={`/index`}><img className="index-btn" src={`${PUBLIC_URL}/images/mobile/index-btn.svg`} alt={`btn`}/></NavLink>
                    <img className="menu" src={`${PUBLIC_URL}/images/mobile/menu-icon.svg`} alt={`menu`}  onClick={e=>this.showMents()}/>
                </div>
                <div className={`more-meuns ${menus}`}>
                    <div className="close"  onClick={e=>this.showMents('')}/>
                    <div className="menus-list">
                        <NavLink to={`/index`}>
                            <p className={`item ${pageId==='index'?'on':''}`}   onClick={e=>this.showMents('')}>首页</p>
                        </NavLink>
                        <NavLink to={`/about`}>
                            <p  className={`item ${pageId==='about'?'on':''}`}  onClick={e=>this.showMents('')}>关于光智</p>
                        </NavLink>
                        <NavLink to={`/investment`}>
                            <p  className={`item ${pageId==='investment'?'on':''}`}  onClick={e=>this.showMents('')}>投资案例</p>
                        </NavLink>
                        <NavLink to={`/news`}>
                            <p  className={`item ${pageId==='news'?'on':''}`}  onClick={e=>this.showMents('')}>新闻动态</p>
                        </NavLink>
                        <NavLink to={`/contact`}>
                            <p  className={`item ${pageId==='contact'?'on':''}`}  onClick={e=>this.showMents('')}>联系我们</p>
                        </NavLink>
                    </div>
                    <div className="logo"/>
                </div>
            </div>
        );
    }
}