import React
    from 'react';
import CustomComponent
    from "../CustomComponent";
import ToggleVisible from "../ToggleVisible";
import {PUBLIC_URL} from "../G";
export default class FixedBackground extends CustomComponent{

    render() {
        let {bg,cover}=this.props;
        return (
            <div className="bg-wrap-box">
                <ToggleVisible visible={/\.(jpg|png|gif)$/.test(bg)}>
                    <div className="bg-content" style={{backgroundImage:`url(${bg})`}}/>
                </ToggleVisible>
                <ToggleVisible visible={/\.(mp4)$/.test(bg)}>
                    <div className="bg-content">
                        <video muted="true" loop={true} webkit-playsinline="true" playsInline="true" x-webkit-airplay="deny" x-webkit-wirelessvideoplaybackdisabled='true'  className="video-ele" autoPlay='true' src={bg} x5-video-player-type="h5" x5-video-player-fullscreen="true" poster={cover}></video>
                    </div>
                </ToggleVisible>
            </div>
        );
    }
}